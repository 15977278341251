import React from "react";
import styles from './Homepage.module.css';

const Homepage: React.FC = () => {
    return (
        <div className='container mx-auto'>
            <div className={styles.wrapper}>
                <img src='/images/logotype.webp' alt='IndraCorp Logotype' style={{height: "150px", objectFit: 'contain'}}/>
                <div className={styles.headTitle}>Stránky ve výstavbě</div>
                <div className={styles.item}>
                    <div className={styles.title}>IndraCorp s.r.o.</div>
                    <div>Schodová 1288/9, Brno 602 00</div>
                    <div>IČ: 19873212</div>
                    <div>DIČ: CZ19873212</div>
                    <div>Společnost je zapsána v obchodním rejstříku vedeném<br/> Krajským soudem v Brně oddíl C vložka 136337.</div>
                </div>
                <div className={styles.item}>
                    <div className={styles.title}>Ing. Jan Indra</div>
                    <div>Jednatel společnosti</div>
                    <div><a href='tel:+420704973601'>+420 704 973 601</a></div>
                    <div><a href='mailto:jan.indra@indracorp.cz'>jan.indra@indracorp.cz</a></div>
                </div>
                <div className={styles.item}>
                    <div className={styles.title}>Sabina Indrová</div>
                    <div>Jednatelka společnosti</div>
                    <div><a href='tel:+420739903333'>+420 739 903 333</a></div>
                    <div><a href='mailto:sabina.indrova@indracorp.cz'>sabina.indrova@indracorp.cz</a></div>
                </div>
                <div className={styles.item}>
                    <div className={styles.title}>Fakturace</div>
                    <div><a href='mailto:fakturace@indracorp.cz'>fakturace@indracorp.cz</a></div>
                </div>
            </div>
        </div>
    )
}

export default Homepage